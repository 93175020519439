/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/prefer-default-export */

import { FmdGood, NearMe } from "@mui/icons-material";
import { CircularProgress, Icon, Switch, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { t } from "i18next";
import lod_ from "lodash";

/**
 * Display attachment in line (name + href to attachment)
 * @param {[object]} array - Array of attachments
 * @returns {JSX.Element} - JSX.Element
 */
const attachmentRender = array => {
	return (
		<MDBox>
			{array.map((item, index) => {
				return (
					<>
						<MDTypography
							key={index}
							component="a"
							href={item.url}
							target="_blank"
							variant="caption"
							color="info"
							onClick={e => e.stopPropagation()}
							className="defaultdatatable-cell-link"
						>
							{item.name}
						</MDTypography>
						{index < array.length - 1 && <MDBox sx={{ mb: 1 }} />}
					</>
				);
			})}
		</MDBox>
	);
};

const ColorItemLabel = ({ color }) => {
	return (
		<div
			style={{
				height: "3vh",
				width: "3vh",
				borderRadius: "7px",
				backgroundColor: color
			}}
		></div>
	);
};

const createObjectLevel = (obj, lap = 1) => {
	let keys = Object.keys(obj);

	const getValue = value => {
		try {
			return value.toString();
		} catch (e) {
			return value;
		}
	};

	return (
		<div>
			{keys.map((key, index) => {
				let valueType = typeof obj[key];
				if (valueType === "object") {
					return (
						<MDBox key={index}>
							<MDBox key={index}>
								<MDTypography variant="caption" fontWeight="bold">
									{`${key}:`}
								</MDTypography>
							</MDBox>
							{createObjectLevel(obj[key])}
						</MDBox>
					);
				} else {
					return (
						<MDBox sx={{ ml: lap }} key={index}>
							<MDTypography variant="caption" fontWeight="bold">
								{`└ ${key}:`}
							</MDTypography>
							<MDTypography sx={{ ml: 1 }} variant="caption">
								{getValue(obj[key])}
							</MDTypography>
						</MDBox>
					);
				}
			})}
		</div>
	);
};

const createObjectTable = (obj, cell, dictionary, form) => {
	if (!lod_.isArray(obj)) {
		obj = [obj];
	}

	let sample = obj[0];
	let keys = Object.keys(sample);

	// Special object cases
	if (sample?.type === "attachment") {
		return attachmentRender(obj);
	}

	// Default display all

	const getValue = value => {
		try {
			return value.toString();
		} catch (e) {
			return value;
		}
	};

	const getDictionryItem = key => {
		let collection = form?.collectionDefault;
		let attribute = cell?.column?.id;

		if (!collection || !attribute) return null;

		let midPath = collection + "." + attribute;

		let realPath = midPath + "." + key;
		realPath = realPath.split(".")?.join(".items.");
		let dictionaryItem = lod_.get(dictionary, realPath);

		return dictionaryItem;
	};

	return (
		<div>
			{keys.map((key, index) => {
				let dictionaryItem = getDictionryItem(key);

				let valueType = typeof sample[key];
				if (valueType === "object") {
					return (
						<MDBox key={index}>
							<MDBox key={index}>
								<MDTypography variant="caption" fontWeight="bold">
									{`${dictionaryItem?.label?.fr ?? key}:`}
								</MDTypography>
							</MDBox>
							{createObjectLevel(sample[key])}
						</MDBox>
					);
				} else {
					return (
						<MDBox key={index}>
							<MDTypography variant="caption" fontWeight="bold">
								{`${key}:`}
							</MDTypography>
							<MDTypography sx={{ ml: 1 }} variant="caption">
								{getValue(sample[key])}
							</MDTypography>
						</MDBox>
					);
				}
			})}
		</div>
	);
};

const createArrayTable = arr => {
	return <div>{arr.join(", ")}</div>;
};

/**
 * ##############################
 * Display special fields in table
 * e.g : colors, icons, images, etc
 * ##############################
 */
export function handleSpecialCell(
	cell,
	rowKey,
	dictionary,
	form,
	chart,
	displayDictionaryName = null,
	extra
) {
	let dictionaryPath = (displayDictionaryName ?? chart?.request?.collection) + "." + cell.column.id;
	dictionaryPath = dictionaryPath.replaceAll(".", ".items.");
	let dictionaryItem = lod_.get(dictionary, dictionaryPath);

	const TYPE_DATE = ["date", "datetime"];
	const TYPE_DURATION = ["timestamp"];
	const TYPE_IMAGES = ["imageList"];
	const TYPE_CODE = ["code"];

	let filtersLabel = [];

	switch (cell.column.id) {
		case "data.header.sendingDate":
			return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
		case "filters":
			cell.value.map((filter, index) => {
				let realPath = filter.dictionaryPath?.split(".")?.join(".items.");
				let fromDictionary = lod_.get(dictionary, realPath);
				if (fromDictionary && fromDictionary.analytics && fromDictionary.active) {
					filtersLabel.push(fromDictionary.label.fr);
				}
			});
			return <div>{filtersLabel.join(" / ")}</div>;

		case "labels":
			return (
				<MDBox>
					{!lod_.isEmpty(cell.value) ? (
						<>
							{Object.keys(cell.value).map((childItem, key) => {
								return (
									<div style={{ display: "flex" }}>
										<span style={{ fontWeight: "bolder" }}>{childItem} </span>
										<span>{" : " + cell.value[childItem]}</span>
									</div>
								);
							})}
						</>
					) : (
						<MDBox></MDBox>
					)}
				</MDBox>
			);

		case "color":
			return <ColorItemLabel color={cell.value} />;
		case "display.pages":
			return <div>{cell.value.join(", ")}</div>;
		case "images":
			if (!cell.value) return null;
			return <div>voir (bientot dispo)</div>;
		case "createdDate":
			return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
		case "active":
		case "analysed":
			if (!cell.value) {
				return (
					<Icon color="error" fontSize="medium">
						close
					</Icon>
				);
			} else {
				return (
					<Icon color="success" fontSize="medium">
						check
					</Icon>
				);
			}
		default:
			/**
			 * Collections
			 */
			if (cell.column.id === "state" && form?.collectionDefault === "acknowledgmentOfReceipt") {
				switch (cell.value) {
					case "pending":
						return (
							<Tooltip placement="top" title="En attente">
								<Icon fontSize="medium">hourglass_empty</Icon>
							</Tooltip>
						);
					case "sending":
						return (
							<Tooltip placement="top" title="En cours d'envoi">
								<Icon fontSize="medium">schedule_send</Icon>
							</Tooltip>
						);
					case "sent":
						return (
							<Tooltip placement="top" title="Envoyé">
								<Icon fontSize="medium">check</Icon>
							</Tooltip>
						);
					case "error":
						return (
							<Tooltip placement="top" title="En erreur">
								<Icon fontSize="medium">error</Icon>
							</Tooltip>
						);
					case "cancelled":
						return (
							<Tooltip placement="top" title="Annulé">
								<Icon fontSize="medium">cancel</Icon>
							</Tooltip>
						);
					default:
						return cell.render("Cell");
				}
			}
			/**
			 * Dictionary case handling
			 */
			if (dictionaryItem && TYPE_DATE.includes(dictionaryItem.type)) {
				return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
			}
			if (!lod_.isNil(cell?.value) && typeof cell.value === "boolean") {
				return <Switch checked={cell.value} />;
			}

			if (dictionaryItem && TYPE_IMAGES.includes(dictionaryItem.type)) {
				return (
					<MDBox
						component="img"
						src={cell.value}
						style={{
							height: "3rem",
							width: "3rem",
							objectFit: "contain"
						}}
					/>
				);
			}

			if (dictionaryItem && TYPE_CODE.includes(dictionaryItem.type)) {
				let element = dictionaryItem.codes.find(code => code.value === cell.value);
				let label = element ? element.label : cell.value;
				return <span>{label}</span>;
			}
			/**
			 * Collection
			 */
			switch (chart.request.collection) {
				case "channel": {
					let possibleMessage = cell.row.values?.statusMessage;
					let message = null;
					if (possibleMessage) {
						if (i18n.exists(`CHANNEL.ERRORS.${possibleMessage}`)) {
							message = t(`CHANNEL.ERRORS.${possibleMessage}`);
						} else {
							message = possibleMessage;
						}
					}
					switch (cell.column.id) {
						case "status": {
							switch (cell.value) {
								case "operational":
									return (
										<Tooltip placement="top" title="Fonctionnel">
											<Icon fontSize="medium" color="success">
												check_circle
											</Icon>
										</Tooltip>
									);
								case "testing":
									return (
										<Tooltip placement="top" title="En cours de test">
											<MDBox>
												<CircularProgress color="info" size={20} />
											</MDBox>
										</Tooltip>
									);
								case "errored":
									return (
										<Tooltip placement="top" title={message}>
											<Icon fontSize="medium" color="error">
												cancel
											</Icon>
										</Tooltip>
									);
								default:
									return (
										<Tooltip placement="top" title="Pas encore testé">
											<Icon fontSize="medium" color="error">
												pending
											</Icon>
										</Tooltip>
									);
							}
						}
						default:
							break;
					}
					break;
				}
				case "answerItem": {
					switch (cell.column.id) {
						case "channelsCode": {
							let channels = [
								{
									code: "ALL",
									name: t("COMPONENT.ANSWERTYPE.allChannels"),
									description: t("COMPONENT.ANSWERTYPE.allChannels"),
									icon: "message"
								},
								...extra.channels
							];
							if (!channels) {
								break;
							}

							let mappedChannels = [];

							(cell.value || []).map(channelCode => {
								let channel = channels.find(channel => channel.code === channelCode);
								if (channel) {
									mappedChannels.push(channel);
								}
							});

							return (
								<MDBox display="flex" alignItems="center" flexDirection="row">
									{mappedChannels.map((channel, index) => {
										return (
											<Tooltip title={channel.name} placement="top" key={index}>
												<MDBox display="flex" alignItems="center" m={1}>
													{channel.logoURL && (
														<MDBox
															component="img"
															src={channel.logoURL}
															alt={channel.name}
															style={{
																width: "30px",
																height: "30px",
																// borderRadius: "50%",
																objectFit: "contain"
															}}
														/>
													)}
													{channel.icon && (
														<Icon
															fontSize="medium"
															sx={{
																width: "30px",
																height: "30px"
															}}
														>
															{channel.icon}
														</Icon>
													)}
												</MDBox>
											</Tooltip>
										);
									})}
								</MDBox>
							);
						}
						default:
							break;
					}
					break;
				}
				default:
					break;
			}
			/**
			 * Default case handling
			 */
			if (lod_.isArray(cell.value)) {
				let sample = cell.value[0];

				if (lod_.isObject(sample)) {
					return createObjectTable(cell.value, cell, dictionary, form);
				} else {
					return createArrayTable(cell.value);
				}
			} else if (lod_.isObject(cell.value)) {
				return createObjectTable(cell.value, cell, dictionary, form);
			} else {
				return cell.render("Cell");
			}
	}
}
