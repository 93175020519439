import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";

const BooleanInput = ({ item, value, PI, label, onChange, child }) => {
	const [checked, setChecked] = useState(Boolean(value));

	const description = item?.description ?? null;

	const handleCheck = e => {
		if (PI) {
			setChecked(!checked);
			let event = { target: { name: child, value: !checked } };
			onChange(event);
		}
	};

	// init value for first render
	useEffect(() => {
		let event = { target: { name: child, value: checked } };
		onChange(event);
	}, []);

	return (
		<MDBox
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={e => handleCheck(e)}
		>
			<MDBox display="flex" flexDirection="column">
				<span>{label}</span>
				<MDTypography variant="caption">{description}</MDTypography>
			</MDBox>
			<MDBox>
				<Switch checked={checked} />
			</MDBox>
		</MDBox>
	);
};

export default BooleanInput;
