/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
import lod_ from "lodash";
import i18n from "i18n";
import "./style.css";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDBadge from "components/Basics/MDBadge";
import { Divider, Tooltip } from "@mui/material";
import FeedbackElement from "../FeedbackElement";

const Customerdetails = ({ customer = {} }) => {
	const customerKeys = Object.keys(customer);

	return (
		<MDBox>
			{customerKeys.map((key, index) => {
				const value = customer[key];
				if (lod_.isNil(value)) {
					return <MDBox key={index}></MDBox>;
				}

				const traductionKey = `PAGES.CONVERSATION_CALLS.${key}`;
				const traduction = i18n.t(traductionKey);

				// Use traduction if exists, if not use the key
				const label = traduction === traductionKey ? key : traduction;

				return (
					<MDBox key={index}>
						<MDTypography variant="body2" fontSize="small" display="flex" alignItems="center">
							<MDTypography variant="body2" fontSize="small" fontWeight="bold">
								{label}
							</MDTypography>
							<MDTypography variant="body2" fontSize="small">
								{` : ${value}`}
							</MDTypography>
						</MDTypography>
					</MDBox>
				);
			})}
		</MDBox>
	);
};

const GetSentimentDisplay = ({ sentiment = "Non renseigné" }) => {
	switch (sentiment) {
		case "positive":
			return <MDBadge color="success" badgeContent="Positive" />;
		case "neutral":
			return <MDBadge color="light" badgeContent="Neutral" />;
		case "negative":
			return <MDBadge color="error" badgeContent="Negative" />;
		default:
			return (
				<MDTypography variant="body2" fontSize="small">
					{sentiment}
				</MDTypography>
			);
	}
};

const CustomerTab = ({ phoneCall = {}, resource }) => {
	const resourceID = phoneCall?._id;

	const isConversationAnalysed = Boolean(phoneCall?.analyse);

	let analyse = phoneCall?.analyse || {};
	let feedbacks = analyse?.feedbacks || {};
	let customer = analyse?.customer || {};

	if (!isConversationAnalysed) {
		return (
			<MDBox display="flex" alignItems="center" justifyContent="center">
				<MDTypography variant="h6" sx={{ mt: 0 }}>
					{`Vous devez analyser l'appel avant de voir les détails`}
				</MDTypography>
			</MDBox>
		);
	}

	return (
		<MDBox
			style={{
				overflowY: "auto"
			}}
		>
			<MDBox>
				<MDBox>
					{/* Call details */}
					<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={2}>
						<MDTypography variant="h6" sx={{ mt: 0 }}>
							{`Détails de l'appel`}
						</MDTypography>
					</MDBox>
					{/* Client id */}
					<MDBox display="flex" alignItems="center">
						<MDTypography variant="body2" fontSize="small" fontWeight="bold">
							Client:&nbsp;
						</MDTypography>
						<MDTypography variant="body2" fontSize="small">
							{phoneCall.fromNumber}
						</MDTypography>
					</MDBox>
					{/* Date appel */}
					<MDBox display="flex" alignItems="center">
						<MDTypography variant="body2" fontSize="small" fontWeight="bold">
							Date:&nbsp;
						</MDTypography>
						<MDTypography variant="body2" fontSize="small">
							{new Date(phoneCall.createDate).toLocaleString("FR-fr")}
						</MDTypography>
					</MDBox>
					{/* Durée */}
					<MDBox display="flex" alignItems="center">
						<MDTypography variant="body2" fontSize="small" fontWeight="bold">
							Durée:&nbsp;
						</MDTypography>
						<MDTypography variant="body2" fontSize="small">
							{phoneCall?.call?.duration}
						</MDTypography>
					</MDBox>

					<Divider />
					{/* Customer details */}
					<MDBox display="flex" alignItems="center" justifyContent="space-between">
						<MDBox
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							sx={{ width: "100%" }}
						>
							<MDTypography variant="h6">Client</MDTypography>
							<FeedbackElement
								name="customer"
								element={feedbacks?.customer || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
					</MDBox>
					<Customerdetails customer={customer} />
					<Divider />
					{/* Sentiments */}
					<MDBox mt={2}>
						<MDBox display="flex" alignItems="center" justifyContent="space-between">
							<MDTypography variant="h6" sx={{ mt: 0 }}>
								Sentiments
							</MDTypography>
							<FeedbackElement
								name="sentiments"
								element={feedbacks?.sentiments || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
						<MDBox pl={1} pt={1}>
							<table
								style={{
									width: "100%"
								}}
							>
								<tr>
									<td>
										<MDTypography variant="body2" fontSize="small">
											Sentiment client
										</MDTypography>
									</td>
									<td
										style={{
											textAlign: "right"
										}}
									>
										<GetSentimentDisplay sentiment={analyse?.sentimentClient} />
									</td>
								</tr>
								<tr>
									<td>
										<MDTypography variant="body2" fontSize="small">
											Sentiment marque
										</MDTypography>
									</td>
									<td
										style={{
											textAlign: "right"
										}}
									>
										<GetSentimentDisplay sentiment={analyse?.sentimentMarque} />
									</td>
								</tr>
								<tr>
									<td>
										<MDTypography variant="body2" fontSize="small">
											Sentiment produits
										</MDTypography>
									</td>
									<td
										style={{
											textAlign: "right"
										}}
									>
										<GetSentimentDisplay sentiment={analyse?.sentimentProduits} />
									</td>
								</tr>
							</table>
						</MDBox>
					</MDBox>
					{/* Action of customer */}
					<Divider />
					<MDBox display="flex" alignItems="center" justifyContent="space-between">
						<MDTypography variant="h6" sx={{ mt: 0 }}>
							Actions entreprises par le client
						</MDTypography>
					</MDBox>
					<MDBox>
						<MDBox>
							{(analyse?.actions?.customerActions || []).map((action, index) => {
								return (
									<MDBox key={index} className="highlightFeedbackRow">
										<Tooltip placement="left" title={action.justification}>
											<MDBox
												display="flex"
												alignItems="center"
												justifyContent="space-between"
												sx={{ width: "100%" }}
											>
												<MDBox display="flex" alignItems="center" justifyContent="space-between">
													<MDBox
														style={{
															height: "20px",
															width: "20px"
														}}
														mr={0.5}
														borderRadius="md"
														bgColor={
															action.exists || action.evaluation === "OK" ? "success" : "light"
														}
													></MDBox>
													<MDTypography variant="body2" fontSize="small">
														{action.name.charAt(0).toUpperCase() + action.name.slice(1)}
													</MDTypography>
												</MDBox>

												<FeedbackElement
													name={action.name}
													element={feedbacks[action.name] || {}}
													resource={resource}
													resourceID={resourceID}
												/>
											</MDBox>
										</Tooltip>
									</MDBox>
								);
							})}
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default CustomerTab;
