import { Delete, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import InputType from "./InputType";

const ChildAccordionArrayMapper = ({
	path,
	fieldChild,
	classes,
	values,
	root,
	rootPath,
	PI,
	listAPIs,
	userLangage,
	customErrors,
	handleInputChangeValidate,
	isEmpty,
	formDictionary,
	handleRemoveItemToArray,
	handleAddItemToArray,
	routeDict,
	contextDictionary,
	index,
	checkInputRequired
}) => {
	let displayedSumary = Object.keys(fieldChild)
		.map(key => {
			let item = fieldChild[key];
			if (item.type !== "data" && item.display) {
				return item.value;
			} else {
				return "";
			}
		})
		.filter(val => val)
		.join(", ");

	return (
		<Accordion style={{ margin: "0.5em 0 0 0" }} className="customAccordion" key={index}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{!lod_.isNil(index) && (
					<Box style={{ flex: "0 0 50px" }}>
						<div className={classes.numberCircle}>{index + 1}</div>
					</Box>
				)}

				<MDBox className="accordionSummaryText" flex="2">
					{displayedSumary}
				</MDBox>
				<MDBox display="flex" justifyContent="end" alignItems="center" width="100%" flex="1">
					<IconButton
						size="small"
						onClick={e => {
							handleRemoveItemToArray(index, path);
							e.stopPropagation();
						}}
					>
						<Delete />
					</IconButton>
				</MDBox>
			</AccordionSummary>
			<AccordionDetails>
				{Object.keys(fieldChild)
					.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
					.map((child, key) => {
						if (
							fieldChild[child].type === "level" &&
							fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							return (
								<>
									{fieldChild[child].value.map((childArray, keyArray) => {
										let rootPathArray = root === "" ? `${root}` : root + `.${child}[${keyArray}]`;
										let rootPathArrayDico =
											rootPath === "" ? `${rootPath}` : rootPath + `.${child}.value[${keyArray}]`;

										return (
											<ChildAccordionArrayMapper
												path={path}
												fieldChild={fieldChild[child].value[keyArray]}
												index={keyArray}
												contextDictionary={contextDictionary}
												isEmpty={isEmpty}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootPathArray}
												rootPath={rootPathArrayDico}
												PI={PI}
												listAPIs={listAPIs}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleRemoveItemToArray={handleRemoveItemToArray}
												handleAddItemToArray={handleAddItemToArray}
												handleInputChangeValidate={handleInputChangeValidate}
												checkInputRequired={checkInputRequired}
												routeDict={routeDict}
												key={keyArray}
											/>
										);
									})}
								</>
							);
						} else if (
							fieldChild[child].type === "level" &&
							!fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArray = root === "" ? `${root}` : root + `.${child}`;
							let rootPathArrayDico = rootPath === "" ? `${rootPath}` : rootPath + `.${child}`;
							return (
								<ChildAccordionArrayMapper
									path={path}
									fieldChild={fieldChild[child]}
									title={fieldChild[child][userLangage]}
									classes={classes}
									isEmpty={isEmpty}
									userLangage={userLangage}
									root={rootPathArray}
									rootPath={rootPathArrayDico}
									values={values}
									keyArray={key}
									listAPIs={listAPIs}
									PI={PI}
									handleInputChangeValidate={handleInputChangeValidate}
									checkInputRequired={checkInputRequired}
									formDictionary={formDictionary}
									handleRemoveItemToArray={handleRemoveItemToArray}
									handleAddItemToArray={handleAddItemToArray}
									customErrors={customErrors}
								></ChildAccordionArrayMapper>
							);
						} else if (
							fieldChild[child].type !== "level" &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArrayDico = rootPath === "" ? `${child}` : rootPath + `.${child}`;
							let rootPathArray = root === "" ? `${child}` : root + `.${child}`;
							let formConfig = lod_.get(formDictionary, rootPathArrayDico);
							return (
								<Box key={key}>
									<InputType
										regexEmail=""
										listAPIs={listAPIs}
										child={rootPathArray}
										whitelistDynamic={formConfig?.whitelistDynamic}
										isArray={formConfig?.isArray}
										whitelist={formConfig?.whitelist}
										isDisplay={formConfig?.display}
										codes={formConfig?.codes}
										label={fieldChild[child][userLangage]}
										isEdit={fieldChild[child]?.isEdit}
										isRequired={formConfig?.isRequired}
										key={key}
										PI={PI}
										isEmpty={isEmpty}
										type={formConfig?.type}
										valueForm={values}
										value={lod_.get(values, rootPathArray)}
										customErrors={lod_.get(customErrors, rootPathArray)}
										onChange={e => handleInputChangeValidate(e, formConfig, rootPathArray)}
										values={values}
										routeDict={routeDict}
										defaultValueForItem={fieldChild[child]?.defaultValue}
										checkRequired={valueInput => checkInputRequired(valueInput, rootPathArray)}
									></InputType>
								</Box>
							);
						} else {
							return null;
						}
					})}
			</AccordionDetails>
		</Accordion>
	);
};

export default ChildAccordionArrayMapper;
