/* eslint-disable no-underscore-dangle */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { Divider, Tooltip } from "@mui/material";
import lod_ from "lodash";
import FeedbackElement from "../FeedbackElement";

const AnalyzeTab = ({ phoneCall, resource }) => {
	const resourceID = phoneCall?._id;

	const analyse = phoneCall?.analyse || {};

	const isConversationAnalysed = Boolean(analyse);

	const products = analyse?.products || [];
	const feedbacks = analyse?.feedbacks || {};

	const DEFAULT_TXT = "Non renseigné";

	if (!isConversationAnalysed) {
		return (
			<MDBox display="flex" alignItems="center" justifyContent="center">
				<MDTypography variant="h6" sx={{ mt: 0 }}>
					{`Vous devez analyser l'appel avant de voir les détails`}
				</MDTypography>
			</MDBox>
		);
	}

	return (
		<MDBox style={{ overflowY: "auto" }}>
			{analyse ? (
				<MDBox>
					{/* Main topic */}
					<MDBox>
						<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={2}>
							<MDTypography variant="h6" sx={{ mt: 0 }}>
								Topic principal
							</MDTypography>
							<FeedbackElement
								name="mainTopic"
								element={feedbacks?.mainTopic || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
						<MDBox p={1}>
							<MDTypography variant="body2" fontSize="small">
								{analyse?.mainTopic || DEFAULT_TXT}
							</MDTypography>
						</MDBox>
					</MDBox>
					<Divider />
					{/* Resume */}
					<MDBox>
						<MDBox display="flex" alignItems="center" justifyContent="space-between">
							<MDTypography variant="h6" sx={{ mt: 0 }}>
								Résumé
							</MDTypography>
							<FeedbackElement
								name="summary"
								element={feedbacks?.summary || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
						<MDBox p={1}>
							<MDTypography variant="body2" fontSize="small">
								{analyse?.summary || DEFAULT_TXT}
							</MDTypography>
						</MDBox>
					</MDBox>
					<Divider />
					{/* Topics */}
					<MDBox>
						<MDBox display="flex" alignItems="center" justifyContent="space-between">
							<MDTypography variant="h6" sx={{ mt: 0 }}>
								Topics
							</MDTypography>
							<FeedbackElement
								name="topics"
								element={feedbacks?.topics || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
						<MDBox p={1}>
							{!lod_.isEmpty(analyse?.topics) ? (
								<MDTypography variant="body2" fontSize="small">
									{analyse.topics.join(", ")}
								</MDTypography>
							) : (
								<MDTypography variant="body2" fontSize="small">
									{DEFAULT_TXT}
								</MDTypography>
							)}
						</MDBox>
					</MDBox>
					<Divider />
					{/* Topics analyze */}
					<MDBox>
						<MDBox display="flex" alignItems="center" justifyContent="space-between">
							<MDTypography variant="h6" sx={{ mt: 0 }}>
								Analyse des Topics
							</MDTypography>
							<FeedbackElement
								name="topicsAnalysis"
								element={feedbacks?.topicsAnalysis || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
						<MDBox p={1}>
							{!lod_.isEmpty(analyse?.topics) && !lod_.isEmpty(analyse?.topicsAnalyze) ? (
								<MDTypography variant="body2" fontSize="small">
									{analyse.topics.map((topic, index) => {
										return (
											<MDBox key={index}>
												<MDTypography variant="body2" fontSize="small" fontWeight="bold">
													{topic}:
												</MDTypography>
												<MDTypography variant="body2" fontSize="small">
													{analyse.topicsAnalyze[topic]}
												</MDTypography>
											</MDBox>
										);
									})}
								</MDTypography>
							) : (
								<MDTypography variant="body2" fontSize="small">
									{DEFAULT_TXT}
								</MDTypography>
							)}
						</MDBox>
					</MDBox>
					{/* Products */}
					<MDBox display="flex" alignItems="center" justifyContent="space-between" mt={2}>
						<MDBox
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							sx={{ width: "100%" }}
						>
							<MDTypography variant="h6">Produits</MDTypography>
							<FeedbackElement
								name="products"
								element={feedbacks?.products || {}}
								resource={resource}
								resourceID={resourceID}
							/>
						</MDBox>
					</MDBox>
					<MDBox>
						{products.length === 0 && (
							<MDBox>
								<MDTypography variant="body2" fontSize="small">
									Aucun produit
								</MDTypography>
							</MDBox>
						)}
						{products.map((product, index) => {
							return (
								<MDBox key={index}>
									<MDTypography variant="body2" fontSize="small">
										{`- ${product}`}
									</MDTypography>
								</MDBox>
							);
						})}
					</MDBox>
					{/* Action of agent */}
					<Divider />
					<MDBox display="flex" alignItems="center" justifyContent="space-between">
						<MDTypography variant="h6" sx={{ mt: 0 }}>
							{`Actions entreprises par l'agent`}
						</MDTypography>
					</MDBox>
					<MDBox>
						<MDBox>
							{(analyse?.actions?.agentActions || []).map((action, index) => {
								return (
									<MDBox key={index} className="highlightFeedbackRow">
										<Tooltip placement="left" title={action.justification}>
											<MDBox
												display="flex"
												alignItems="center"
												justifyContent="space-between"
												sx={{ width: "100%" }}
											>
												<MDBox display="flex" alignItems="center" justifyContent="space-between">
													<MDBox
														style={{
															height: "20px",
															width: "20px"
														}}
														mr={0.5}
														borderRadius="md"
														bgColor={
															action.exists || action.evaluation === "OK" ? "success" : "light"
														}
													></MDBox>
													<MDTypography variant="body2" fontSize="small">
														{action.name.charAt(0).toUpperCase() + action.name.slice(1)}
													</MDTypography>
												</MDBox>

												<FeedbackElement
													name={action.name}
													element={feedbacks[action.name] || {}}
													resource={resource}
													resourceID={resourceID}
												/>
											</MDBox>
										</Tooltip>
									</MDBox>
								);
							})}
						</MDBox>
					</MDBox>
					<MDBox mt={10}></MDBox>
				</MDBox>
			) : (
				<MDBox
					style={{
						height: "100%"
					}}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<MDTypography variant="h6">Analyse non effectuée pour cette conversation</MDTypography>
				</MDBox>
			)}
		</MDBox>
	);
};

export default AnalyzeTab;
