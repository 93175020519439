/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */

import lod_ from "lodash";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Badge,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Menu,
	MenuItem
} from "@mui/material";
import { t } from "i18next";

import { display } from "redux-react/reducers/snackBarReducer";
import ContactsActions from "redux-react/actions/contactsActions";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import FusionContent from "../fusion/FusionContent";

const ActionFusionContact = ({
	route,
	dictionary,
	selectedRows,
	setSelectedRows,
	reloadTable,
	setReloadTable
}) => {
	const dispatch = useDispatch();
	const { profile } = useSelector(state => state);

	const [anchorElMenu, setAnchorElMenu] = useState(null);

	const [fusionElements, setFusionElements] = useState({});

	const [fusionDialog, setFusionDialog] = useState(false);
	const [fusionResult, setFusionResult] = useState({});

	const canUseFusion = route => {
		let access = false;

		if (route.route === "/contact") {
			access = true;
		}

		return access;
	};

	const createFusionContacts = () => {
		let data = {
			contacts: selectedRows.map(el => el._id)
		};

		dispatch(
			ContactsActions.createFusionContacts(profile.assistantID, data, ({ fusion }) => {
				setAnchorElMenu(null);
				setFusionElements(fusion);
				setFusionDialog(true);
			})
		);
	};

	return (
		<MDBox>
			{canUseFusion(route) && (
				<Badge
					badgeContent={selectedRows.length}
					color="error"
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
				>
					<MDButton
						disabled={lod_.isEmpty(dictionary) || lod_.isNil(dictionary.contact)}
						variant="contained"
						color="dark"
						onClick={e => {
							setAnchorElMenu(e.currentTarget);
						}}
					>
						<Icon>list_alt</Icon>&nbsp;Actions contact
					</MDButton>
				</Badge>
			)}

			{/* Menu */}
			<Menu
				anchorEl={anchorElMenu}
				open={Boolean(anchorElMenu)}
				onClose={() => setAnchorElMenu(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{/* Actions */}
				<MenuItem
					disabled={selectedRows.length < 2}
					onClick={() => {
						createFusionContacts();
					}}
				>
					<Icon>people</Icon>&nbsp;{t("FORMS.FUSION.actionFusion")}
				</MenuItem>
			</Menu>

			{/* Fusion Dialog */}
			<Dialog
				open={fusionDialog}
				onClose={() => setFusionDialog(false)}
				fullWidth
				PaperProps={{
					sx: {
						height: "100%",
						width: "100%",
						maxWidth: "100%"
					}
				}}
			>
				<DialogTitle>{t("FORMS.FUSION.titleActionFusion")}</DialogTitle>
				<DialogContent>
					<FusionContent
						dictionaryContact={dictionary?.contact}
						fusionElements={fusionElements}
						onChange={finalContact => setFusionResult(finalContact)}
					/>
				</DialogContent>
				<DialogActions>
					<MDButton
						autoFocus
						onClick={() => {
							setFusionDialog(false);
							setFusionElements({});
							setSelectedRows([]);
						}}
						variant="outlined"
						color="info"
					>
						{t("FORMS.cancel")}
					</MDButton>
					<MDButton
						onClick={() => {
							let data = {
								newContact: fusionResult,
								oldContactsFRU: fusionElements.contacts.map(el => el.FRU)
							};

							dispatch(
								ContactsActions.validateFusionContacts(profile.assistantID, data, res => {
									dispatch(
										display({
											message: t("FORMS.FUSION.fusedContact"),
											type: "success"
										})
									);
									setFusionDialog(false);
									setSelectedRows([]);
									setReloadTable(!reloadTable);
								})
							);
						}}
						color="info"
						variant="contained"
						autoFocus
					>
						{t("FORMS.validate")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</MDBox>
	);
};

export default ActionFusionContact;
